<template>
  <div class="zone">
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" :isClear="false" :hide-search-btn="true" :isOrder="true" :isSelected="isSelected"
                  @searchBtn="searchBtn" @addCheck="editCheck('0')" @clearSearch="clearSearch" >
      <template>
        <li class="keyword_search search_line_one">
          <el-input placeholder="关键字：订单编号/商品名称/经销商/医院/子公司" @clear="getList" @keyup.enter.native="getList" v-model="searchParams.search" clearable size="small"></el-input>
        </li>
        <li class="search_line_one">
          <el-select v-model="searchParams.order_status" placeholder="请选择订单状态" @change="getList" size="small" filterable clearable>
            <el-option
                v-for="item in orderStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li class="search_line_one">
          <el-date-picker
              size="small"
              v-model="dateValue"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="marginLeft:10px"
              class="Calendar"
              @change="dateChange"
              @clear="clearSearchTime"
          />
        </li>
        <li class="search_line_one">
          <el-button type="primary" size="small" @click="searchBtn"><i style="margin-right: 4px" class="el-icon-search"/>搜&nbsp;索</el-button>
          <el-button  size="small"  @click="clearSearch"  ><i style="margin-right: 4px" class="el-icon-refresh" />重&nbsp;置</el-button>
        </li>

      </template>
    </functionBox>
<!--    :style=" 'height:' + tableHeight+ 'px'"-->
    <div class="sheet" ref="sheet" :class="{'empty' : orderList.length ==0,'no-scroll': loading }"  v-loading="loading" element-loading-text="数据加载中">
      <div class="empty-container"  v-if="!loading && orderList.length ==0">
        <el-empty description="暂无数据"></el-empty>
      </div>
      <div class="orderList" v-else>
        <ul>
          <el-checkbox-group v-model="checkedList" @change="handleCheckedOrderChange">
            <li class="orderItem" v-for="order in orderList">
              <el-row class="orderItemTop">
                <el-col :span="5">
                  <span  style="margin-left: 20px"> 订单号：{{order.order_code}}</span>
                </el-col>
                <el-col :span="16"><span> 下单时间：{{order.create_time}}</span></el-col>
                <el-col :span="3"><span :style="statusColor(order.order_status_name)" style="font-weight: 400"> 当前状态：{{order.order_status_name}}</span></el-col>
              </el-row>
              <el-row class="orderItemContent">
                <el-col :span="19">
                  <el-col :span="24">
                    <el-col :span="6"> 订单总额：{{order.total_price}}&nbsp;元</el-col>
                    <el-col :span="18"> 开票信息：{{order.invoice_info.invoice_header}}&nbsp;/&nbsp;{{order.invoice_info.registration_code}}
                      &nbsp;/&nbsp;{{order.invoice_info.contact_person}}&nbsp;/&nbsp;{{order.invoice_info.contact_email}}</el-col>
                  </el-col>
                  <el-col :span="24"><span> 收货信息：{{order.receive_address.customer_contact}}&nbsp;/&nbsp;{{order.receive_address.customer_phone}}&nbsp;/&nbsp;{{order.receive_address.customer_address}}</span></el-col>
                </el-col>
                <el-col class="contentRight" :span="5">
                  <span :class="isForbidOrd ? 'isForbidOrd' : '' " @click="orderGoods(order)" style="color: #E99D42;border-color:#E99D42;">再次订购</span>
                  <span  @click="openDrawer(order)">查看详情</span>
                </el-col>
              </el-row>
            </li>
          </el-checkbox-group>
        </ul>
      </div>

    </div>
    <el-pagination
        v-if="orderList.length > 0"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="searchParams.size"
        :total="searchParams.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParams.page"
        layout="total, sizes, prev, pager, next"
        class="page"
        background
        :pager-count="3"
        >
    </el-pagination>
    <pendingApproval ref="pendingApproval" :orderId="selectedOrderId" @updateData="updateData"></pendingApproval>
    <detailDrawer  ref="detailDrawer"  :orderId="selectedOrderId"></detailDrawer>
    <el-dialog :visible.sync="authenVisible" :show-close=" authenStatus== 5003 ? true : false"  @close="handleAuthenClose" :close-on-click-modal="false">
      <div class="authen_box">
        <img src="@/assets/img/cert.png" alt="" v-if="authenStatus !== 5003">
        <img src="@/assets/img/failTip.png" class="fail" alt="" v-if="authenStatus == 5003">
        <p v-if="authenStatus== 5004">您还未完成客户认证，认证成功后可进行相关操作</p>
        <p v-if="authenStatus== 5001">客户认证正在审核中，请稍后再来！</p>
        <p v-if="authenStatus== 5003">客户认证失败，请稍后重试！</p>
        <div class="authen_button" v-if="authenStatus== 5004">
          <el-button type="primary" @click="goAuthen">立即认证</el-button>
          <el-button @click="goPrePage">稍后认证</el-button>
        </div>
        <p class="text">如有其他疑问，可联系商务进行沟通，商务QQ：3007359468/3007359467</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import FunctionBox from "@/views/Statistics/components/functionBox.vue";
import common from "@/views/OrderManagement/mixin/common";
import PlatSelect from "@/components/PlatSelect.vue";
import pendingApproval from "@/views/OrderManagement/custom/components/pendingApproval.vue";
import detailDrawer from "@/views/OrderManagement/custom/components/orderDetail.vue";
import {addCart, checkOrderDtlList, checkOrderList, getCustomInfo} from "@/api/orderGoods/custom";

export default {
  components: {PlatSelect, FunctionBox,pendingApproval,detailDrawer},
  mixins: [common],
  data() {
    return {
      isForbidOrd: false,
      isOrderExport: 0,
      tableHeight: 400,
      pendingArroval: false,
      loading: false,
      isDetail: false,
      isSelected: false,
      checked: false,
      checkAll : false,
      isDrawerShow: false,
      innerDrawerShow: false,
      isAllChecked: false,
      dateValue: [],
      selectedOrderId: '',
      checkedList: [],
      searchParams: {
        search: '',
        order_status: null,
        start_date: '',
        end_date: '',
        page: 1,
        size: 10,
        total: 0,
      },
      authenVisible: false,
      authenStatus: null,
    }
  },
  computed: {
    isIndeterminate:{
      get(){},
      set(){
        // 当部分选中时，全选框为不确定状态
        return this.checkedList.length > 0 && this.checkedList.length < this.orderList.length;
      },
    },
  },

  created() {
    this.orderStatusList = this.orderStatusList.filter(item => item.value !== 9003)
    this.getTableHeight()
    this.getList()
    this.getCustomInfo()
    let checkOrderId = localStorage.getItem('checkOrderId') || undefined
    if(checkOrderId){
      this.selectedOrderId = checkOrderId
      setTimeout(()=>{this.$refs.pendingApproval.isDrawerShow = true},1000)
    }
  },
  methods: {
    handleAuthenClose(){
      this.authenVisible = false
      this.$router.push('/SearchPage')
    },
    getCustomInfo(){
      getCustomInfo({}).then(res=>{
        this.authenStatus = res.data.invoice_info.is_certified
        if([5001,5003,5004].includes(this.authenStatus)){
          this.authenVisible = true
        }
      })
    },
    updateData(){
      console.log('updateData')
      this.getList()
    },
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      var viewportWidth =  window.innerWidth
      // var element = document.getElementById('pagination');
      if(viewportWidth > 2400){
        this.tableHeight = viewportHeight - 260
      }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
        this.tableHeight = viewportHeight - 266
      }else{
        this.tableHeight = viewportHeight - 216
      }
    },
    getList(val){
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const blockWrapper = this.$refs.sheet
        window.requestAnimationFrame(() => {
          blockWrapper.scrollTop = 0;
        });
      });
      this.loading = true
      checkOrderList(this.searchParams).then(res => {
        this.searchParams.total = res.data.count
        this.orderList = res.data.data
      }).catch(err => {
        if( err.status === 'error' && this.searchParams.page >1 ){
          this.searchParams.page = 1
          this.getList()
        }else{
          this.$message.error(err.msg)
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    orderGoods(order){
      if(this.isForbidOrd){
        return
      }
      let cart = []
     this.isForbidOrd = true
      checkOrderDtlList({order_id: order.order_id}).then(res=>{ // 获取订单里的商品列表
        this.tradeTableData = res.data
        this.tradeTableData.forEach(item => {
          cart.push({
            inventory_id: Number(item.inventory_id),
            quantity: item.orders_num
          })
        })
        setTimeout(() => {
          addCart({cart: cart}).then(res => {
            if(res.code == 200){
              this.$message.success('订购成功')
              order.isOrder = true
              this.$router.push({path: '/odrMgt/custom/proList'})
              this.isForbidOrd = false
            }else{
              this.$message.error(res.data.msg)
            }
          },1000)
        })
      })
    },
    handleAllCheckedChange(value) {
      // 全选/全不选时更新checkedList
      if (value) {
        this.checkedList = this.orderList.map(order => order.id);
      } else {
        this.checkedList = [];
      }
      if(this.checkedList.length > 0){
        this.isOrderExport = 1
      }else{
        this.isOrderExport = 0
      }
      this.isIndeterminate = false;
    },
    handleSizeChange(val){
      this.searchParams.size = val
      this.getList()
    },
    handleCurrentChange(val){
      this.searchParams.page = val
      this.getList()
    },
    handleCheckedOrderChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.orderList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderList.length;
      if(this.checkedList.length > 0){
        this.isOrderExport = 1
      }else{
        this.isOrderExport = 0
      }
    },
    openDrawer(row){
      this.selectedOrderId = row.order_id.toString()
      if([9001,9006].includes(row.order_status)){
        this.$refs.pendingApproval.isDrawerShow = true
      }else{
        this.$refs.detailDrawer.isDrawerShow = true
      }
    },
    dateChange(){
      if( this.dateValue === [] ||  this.dateValue == null){
        this.searchParams.start_date = ''
        this.searchParams.end_date = ''
      }else{
        this.searchParams.start_date = this.dateValue[0]
        this.searchParams.end_date = this.dateValue[1]
      }
      this.getList()
    },
    clearSearchTime(){
      this.dateValue = ['','']
      this.$set(this.searchParams, 'start_date ', '')
      this.$set(this.searchParams, 'end_date ', '')
    },
    searchBtn() {
      this.getList()
    },
    goAuthen(){
      this.$router.push({path: '/authen'})
    },
    goPrePage(){
      this.$router.push('/SearchPage')
      // this.$router.go(-1)
    },
    clearSearch() {
      this.searchParams.search = ''
      this.searchParams.order_status =''
      this.searchParams.page = 1
      this.dateValue = []
      this.searchParams.start_date = ''
      this.searchParams.end_date = ''
      this.getList()
      // this.searchBtn = false
    },
    handlePlatSelect(val){
      this.searchParams.platform_id = val
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";

.search_line_one{
  margin-top: 0!important;
}
.zone{
  //background: #EFEFEF;
  position: relative;
  .indentBox{
    .below{
      .left{
        width: 50%!important;
      }
    }
  }
  ::v-deep .indentBox .el-button{
    padding:10px 16px;
    font-size:14px;
  }
  .sheet{
    position: relative;
    overflow: auto;
    margin-top: 8px;
    height: calc(100% - 140px);
  }
  .sheet.no-scroll { // loading时不允许滚动
    overflow: hidden;
    height: calc(100% - 140px);
  }
  .orderList{
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
    .orderItem{
      color: #6C6C6C;
      background: #fff;
      border-radius: 4px;
      padding: 4px 0px;
      margin-bottom: 8px;
      .orderItemTop{
        line-height: 2.4;
        border-bottom: 1px solid #eee;
        color: #000;
        ::v-deep .el-checkbox{
          margin: 0 10px 0 16px;
          .el-checkbox__label{
            display: none;
          }
        }
        span{
          font-size: 14px;
          font-weight: bold;
        }
      }
      .orderItemContent{
        line-height: 2.5;
        margin: 4px 16px;
        .contentRight{
          margin-top: 24px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          //line-height: 48px;
          span{
            //margin-top: 18px;
            border:1px solid #4095E5;
            color: #4095E5;
            line-height: 2;
            padding: 3px 20px;
            cursor: pointer;
          }
          span.isForbidOrd{
            cursor: not-allowed;
          }
          span:first-child{
            margin-right: 10px;
          }
        }

      }
      .orderItemBottom{
        line-height: 2;
        padding: 4px 16px;
        box-sizing: border-box;
        border-top:1px solid #eee;
        color: #BD3124;
      }
    }
    .orderItem:last-child{
      margin-bottom: 0;
    }
  }
}
.page{
  position: absolute;
  bottom: 0px;
  right: 14px;
  //background: #fff;
  width: calc(100% - 28px);
  padding:10px 20px 20px 20px;
  box-sizing: border-box;
}
::v-deep .keyword_search .el-input__inner{
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .zone .sheet {
    overflow: auto!important;
    height: calc(100% - 136px)!important;
  }
  .keyword_search{
    width: 320px!important;
    font-size: 14px;
  }
  ::v-deep .keyword_search .el-input__inner{
    font-size: 13px;
  }
  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 13px;
  }
  .orderItem{
    font-size: 14px;
    .orderItemTop{
      font-size: 16px;
    }
  }
  ::v-deep .page .el-input--mini .el-input__inner{
    height: 28.5px!important;
    line-height: 1!important;
  }
  .contentRight span{
    font-size: 14px;
  }
}
@media screen and (min-width: 1551px) , print {
  .keyword_search{
    width: 390px!important;
  }
  .contentRight span{
    font-size: 16px;
  }
  ::v-deep .page .el-input--mini .el-input__inner{
    height: 38.4px!important;
    line-height: 1!important;
  }
  .keyword_search{
    width: 260px;
    ::v-deep .el-input__inner{
      font-size: 16px;
    }
  }
  .orderItem{
    font-size: 16px;
    .orderItemTop{
      font-size: 18px;
    }
  }
  .zone .orderList .orderItem .orderItemContent .contentRight{
    margin-top: 24px;
  }

}
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%; /* 设置容器高度为视口高度，以便于居中 */
  //background: #fff;
}
.sheet.empty{
  background: #fff;
  height: calc(100% - 106px);
}
.authen_box{
  text-align: center;
  img{
    width: 294px;
    height: 167px;
    margin-bottom: 40px;
  }
  img.fail{
    width: 185px;
    height: 185px;
  }
  p{
    font-size: 28px;
    color: #101010;
    margin-bottom: 30px;

  }
  .authen_button{
    margin-bottom: 20px;
    ::v-deep .el-button{
      width:300px;
      height: 50px;
      line-height: 50px;
      padding: 0;
      font-size: 18px;
    }
  }
  p.text{
    font-size: 20px;
  }
}
</style>